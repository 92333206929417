import { PhoneIcon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/next-js';
import { Button, IconButton, useMediaQuery } from '@chakra-ui/react';
import { FC } from 'react';
import { TSocialMediaLinksProps, phoneFormat } from '../lib';

const PhoneButton: FC<TSocialMediaLinksProps> = ({ data }) => {
  const [isMobile] = useMediaQuery('(max-width: 992px)');

  const { phone } = data;

  if (!phone) return;

  return isMobile ? (
    <IconButton
      aria-label='call to us'
      as={Link}
      colorScheme='secondary'
      data-gtm='phone-header'
      href={`tel:${phone}`}
      icon={<PhoneIcon />}
      p='3xs'
      size='sm'
      variant='solid'
    />
  ) : (
    <Button
      as={Link}
      colorScheme='secondary'
      data-gtm='phone-header'
      href={`tel:${phone}`}
      p='xs'
      size='sm'
      variant='solid'
    >
      {phoneFormat(phone)}
    </Button>
  );
};

export default PhoneButton;
